<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCartStore } from '~/store/cart'
import { useServiceStore } from '~~/store/service'
import { useServiceModal } from '~/store/modal/service'
import link from '~/helper/canonical'
import { useCategoryStore } from '~/store/category'
import { useSaveCardModal } from '~~/store/modal/saveCard'
import { useAddressStore } from '~/store/address'
import { useUserStore } from '~/store/user'
import { useAuthStore } from '~/store/modal/auth'
import { useSelectPaymentModalStore } from '~/store/modal/selectPayment'

const config = useRuntimeConfig()
const route = useRoute()
const categoryStore = useCategoryStore()
const selectPaymentModalStore = useSelectPaymentModalStore()
const { $api } = useNuxtApp()

// список страниц, на которых необходимо показать баннер нерабочих часов
const catalogPagePathList = ['catalog', 'favorite', 'previously-order', 'new-product', 'sale']

const { serviceUnavailable } = storeToRefs(useServiceStore())
const { cartAmount, serviceType } = storeToRefs(useCartStore())
const { enable: enableShowServiceModal } = useServiceModal()
const userStore = useUserStore()
const saveCardModal = useSaveCardModal()
const addressStore = useAddressStore()
const authStore = useAuthStore()
const isNotUserPage = computed(() => !route.path.includes('user'))
const isNotCartPage = computed(() => !route.path.includes('cart'))
const isMainPage = computed(() => route.path === '/')
const isPaymentCompletePage = computed(() => route.path.includes('payment-complete'))
const isCatalogPage = computed(() => catalogPagePathList.some(p => route.path.includes(p)))
const cartCount = computed(() => cartAmount.value)

const order = useActionEntity($api.order.getByID, {
  params: route.query.order_id?.toString() ?? '',
  onSuccess: ({ data }) => {
    if (data?.paymentCardID)
      saveCardModal.enable(data.paymentCardID)
  },
})

onMounted(async () => {
  if (serviceType.value)
    enableShowServiceModal()

  /*
   Запрос используется только после успешной оплаты
  */
  if (isPaymentCompletePage.value)
    order.request()
})

useHead({
  ...link({ exclude: [], data: { route, config } }),
})
</script>

<template>
  <div class="layout">
    <HeaderBar />
    <div class="layout__inner">
      <Header class="layout__header" />
      <ServiceUnavailable v-if="serviceUnavailable" />
      <div class="layout__content-wrapper" :class="{ 'layout__content-wrapper_demo': !addressStore.current }">
        <div v-if="isNotUserPage && isNotCartPage" class="layout__sidebar">
          <ServiceFactoid
            v-if="serviceType && (isCatalogPage || isMainPage)"
            :type="serviceType"
            :page="isMainPage ? 'main' : 'catalog'"
          />
          <CategorySidebar
            v-if="categoryStore.categories.length"
            :list="categoryStore.categories"
            :class="{ 'mt-30': serviceType && (isCatalogPage || isMainPage) }"
          />
        </div>
        <div class="layout__content">
          <NuxtErrorBoundary>
            <template #default>
              <slot />
            </template>
            <template #error="{ error }">
              <div class="layout__error">
                <Error :nuxt-error-boundary="error.value.message" @error="error.value = null" />
              </div>
            </template>
          </NuxtErrorBoundary>
        </div>
        <div
          v-if="isNotUserPage && isNotCartPage"
          class="layout__cart"
          :class="{ layout__cart_modal: selectPaymentModalStore.active }"
        >
          <CCCheckoutController class="layout__cart-el" />
        </div>
      </div>
      <template v-if="!serviceUnavailable">
        <Footer :class="{ 'footer_cart-page': !isNotCartPage }" />
        <Tabbar
          :cart-count="cartCount"
          :is-user="userStore.isUser"
          class="layout__tabbar"
          @click-enter="authStore.enable"
        />
      </template>
    </div>
  </div>
</template>

<style lang="postcss">
.layout {
  width: 100%;

  &__tabbar {
    display: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 1604px;
    min-height: 100%;
    margin: 0 auto;
    background-color: #fff;
  }

  &__content-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 15px 20px 60px;

    &_demo {
      padding-bottom: 140px;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: var(--z-below-blackout);
  }

  &__error{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__sidebar {
    flex-shrink: 0;
    width: 278px;
  }

  &__content {
    flex-grow: 1;
  }

  &__cart {
    position: sticky;
    top: 30px;
    flex-shrink: 0;
    width: 380px;
    height: calc(100vh - 120px);
    margin-left: 30px;
    overflow: hidden;

    &-el {
      height: 100%;
    }

    &_modal {
      z-index: var(--z-above-modal);
    }
  }

  &__mobile-cart {
    display: none;
  }
  @media (max-width: 1300px) {

    &__sidebar {
      width: 200px;
    }

    &__cart {
      width: 340px;
      margin-left: 20px;
    }
  }
  @media (max-width: 1100px) {

    &__sidebar {
      width: 180px;
    }

    &__cart {
      width: 270px;
      margin-left: 20px;
    }
  }
  @media (max-width: 1000px) {

    &__tabbar {
      display: block;
    }

    &__header {
      position: static;
    }

    &__content-wrapper {
      padding: 15px 15px 95px;

      &_demo {
        padding-bottom: 170px;
      }
    }

    &__sidebar {
      display: none;
    }

    &__cart {
      display: none;

      &_modal {
        display: block;
      }
    }
  }
}
</style>
