<script setup lang="ts">
import MobileDetect from 'mobile-detect'

const url = ref('https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US')

onMounted(() => {
  if (new MobileDetect(navigator.userAgent).os() === 'iOS' || new MobileDetect(navigator.userAgent).userAgent() === 'Safari')
    url.value = 'https://apps.apple.com/us/app/05-ru/id1539023015?l=ru/'
  else
    url.value = 'https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US'
})
</script>

<template>
  <nuxt-link
    :to="url"
    target="_blank"
    class="header-bar link link_wu"
  >
    <div class="header-bar__logo">
      <img
        src="./asset/logo.svg"
        alt="Близко"
        class="header-bar__logo-image"
      >
      <span class="header-bar__logo-text">Близко</span>
    </div>

    <div class="header-bar__content">
      <NuxtImg
        src="/images/banner/cola.png"
        alt="Напитки"
        format="webp"
        class="header-bar__cola header-bar__image"
      />

      <NuxtImg
        src="/images/banner/pumpkin.png"
        alt="Овощи"
        format="webp"
        class="header-bar__pumpkin header-bar__image"
      />

      <NuxtImg
        src="/images/banner/milk.png"
        alt="Молочные продукты"
        format="webp"
        class="header-bar__milk header-bar__image"
      />

      <div class="header-bar__title">
        <span class="header-bar__title-start-mobile">Закажите <br>в приложении</span>
        <span class="header-bar__title-start">Закажите с промокодом</span>
        <div class="header-bar__title-promocode">
          <span class="header-bar__title-promocode-value">APP200</span><span class="header-bar__title-promocode-value-mobile">промокод</span>
        </div>
        <span class="header-bar__title-end">в приложении</span>
      </div>

      <NuxtImg
        src="/images/banner/cheese.png"
        alt="Cыр"
        format="webp"
        class="header-bar__cheese header-bar__image"
      />

      <NuxtImg
        src="/images/banner/watermelon.png"
        alt="Арбуз"
        format="webp"
        class="header-bar__watermelon header-bar__image"
      />

      <NuxtImg
        src="/images/banner//grapes.png"
        alt="Ягоды"
        format="webp"
        class="header-bar__grapes header-bar__image"
      />

      <NuxtImg
        src="/images/banner/cola-x-mobile.png"
        alt="Ягоды"
        format="webp"
        class="header-bar__cola-x-mobile header-bar__image"
      />

      <NuxtImg
        src="/imagesbanner/cola-mobile.png"
        alt="Ягоды"
        format="webp"
        class="header-bar__cola-mobile header-bar__image"
      />

      <NuxtImg
        src="/images/banner/watermelon-mobile.png"
        alt="Арбуз"
        format="webp"
        class="header-bar__watermelon-mobile header-bar__image"
      />
    </div>

    <button class="header-bar__button">
      Скачать
    </button>
  </nuxt-link>
</template>

<style scoped lang="postcss">
@font-face {
  font-family: 'RightGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url('/vue/font/rightgrotesk-medium.woff2') format('woff2'), url('/vue/font/rightgrotesk-medium.woff') format('woff');
  font-display: swap;
}

.header-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 0 70px;
  font-family: 'RightGrotesk', sans-serif;
  cursor: pointer;
  background: #F5EE3A;

  @media (max-width: 800px) {
    height: 40px;
  }

  &__image {
    position: absolute;
  }

  &__button {
    height: 38px;
    padding: 10px 23px;
    font-size: 17px;
    line-height: 17px;
    color: #fff;
    background-color: #00B0B8;
    border: none;
    border-radius: 12px;
  }

  &__title {
    position: absolute;
    left: 30%;
    display: flex;
    align-items: center;
    font-size: 26px;
    line-height: 28px;

    &-start-mobile {
      display: none;
    }

    &-promocode {
      padding: 4px 14px;
      margin: 0 12px;
      background-color: #fff;
      border-radius: 12px;
    }

    &-promocode-value-mobile {
      display: none;
    }
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    &-image {
      width: 38px;
      margin-right: 9px;
    }

    &-text {
      font-size: 28px;
      line-height: 32px;
      color: #15252B;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 190px);
    max-width: 1920px;
    height: 100%;
  }

  &__cola {
    left: 33px;
    width: 177px;

    &-mobile {
      display: none;
    }

    &-x-mobile {
      display: none;
    }
  }

  &__pumpkin {
    left: 164px;
    width: 164px;
    height: 50px;
  }

  &__milk {
    left: 293px;
    width: 181px;
    height: 50px;
  }

  &__watermelon {
    right: 132px;
    width: 206px;
    height: 50px;

    &-mobile {
      display: none;
    }
  }

  &__grapes {
    right: 26px;
    width: 154px;
    height: 50px;
  }

  &__cheese {
    right: 313px;
    width: 160px;
    height: 50px;
  }

  @media (max-width: 1870px) {
    padding: 0 50px;

    &__cola {
      left: -30px;
    }

    &__pumpkin {
      left: 90px;
    }

    &__milk {
      left: 213px;
    }

    &__title {
      left: 27%;
    }

    &__cheese {
      right: 264px;
    }

    &__watermelon {
      right: 89px;
    }

    &__grapes {
      right: -10px;
    }
  }

  @media (max-width: 1700px) {
    padding: 0 40px;

    &__milk, &__cheese {
      display: none;
    }

    &__cola {
      left: 6px;
    }

    &__pumpkin {
      left: 150px;
    }

    &__title {
      left: 26%;
    }

    &__watermelon {
      right: 120px;
    }

    &__grapes {
      right: 0px;
    }
  }

  @media (max-width: 1560px) {
    padding: 0 20px;

    &__cola {
      left: -13px;
    }

    &__pumpkin {
      left: 105px;
    }

    &__title {
      left: 23%;
    }

    &__watermelon {
      right: 92px;
    }

    &__grapes {
      right: -4px;
    }
  }

  @media (max-width: 1435px) {
    padding: 0 30px;

    &__pumpkin, &__grapes {
      display: none;
    }

    &__cola {
      left: 1px;
    }

    &__title {
      left: 19%;
    }

    &__watermelon {
      right: 2px;
    }
  }

  @media (max-width: 1295px) {
    padding: 0 20px;

    &__cola {
      left: -19px;
    }

    &__title {
      left: 15%;
    }

    &__watermelon {
      right: -35px;
    }
  }

  @media (max-width: 1178px) {

    &__content {
      width: calc(100% - 245px);
    }

    &__watermelon {
      display: none;
    }

    &__cola {
      left: -32px;
    }
  }

  @media (max-width: 1000px) {

    &__button {
      height: 30px;
      padding: 0px 20px;
      line-height: 16px;
    }

    &__content {
      width: calc(100% - 100px);
    }

    &__title {
      left: 0;

      &-start {
        display: none;
      }

      &-start-mobile {
        display: inline-block;
        max-width: 110px;
        font-size: 16px;
        line-height: 18px;
      }

      &-end {
        display: none;
      }

      &-promocode {
        display: flex;
        flex-direction: column;
        max-width: 82px;
        height: 30px;
        padding: 4px 11px 0px;
        margin-left: 4px;
        text-align: center;
      }

      &-promocode-value {
        margin-bottom: 3px;
        font-size: 18px;
        line-height: 13px;
        color: #15252B;
        text-align: center;
      }

      &-promocode-value-mobile {
        display: inline;
        font-size: 8px;
        line-height: 8px;
        color: #15252B;
      }
    }

    &__logo {
      display: none;
    }

    &__cola {
      display: none;

      &-mobile {
        bottom: 0;
        left: 62%;
        display: block;
        width: 111px;
      }
    }
  }

  @media (max-width: 410px) {
    padding: 0 7px;

    &__content {
      width: 100%;
    }

    &__title {
      justify-content: space-between;
      width: 100%;
    }

    &__title-promocode {
      margin: 0;
    }

    &__cola-mobile {
      display: none;
    }

    &__cola-x-mobile {
      top: 0;
      left: 22%;
      display: block;
      width: 77px;
    }

    &__button {
      display: none;
    }

    &__watermelon-mobile {
      left: 39%;
      display: block;
      width: 108px;
    }
  }
}
</style>
